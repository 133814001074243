/* General Styles */
:root {
    --arcade-bg: #0A0F0D;
    --arcade-blue: #00E5FF;
    --arcade-pink: #FF2D95;
    --arcade-green: #39FF14;
    --arcade-white: #FFFFFF;
  }
body {
    margin: 0;
    padding: 5px;
    background: linear-gradient(135deg, #0A0F0D, #1A1F1D);
    color: var(--arcade-blue);
    font-family: 'Press Start 2P', monospace;
    font-weight: 400;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

.container {
    background-color: var(--arcade-white);
    color: var(--arcade-pink);
    text-shadow: var(--arcade-pink) 0px 0px 10px;
    font-family: inherit;
    padding: 2rem;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 8px; /* Optional: rounded corners for the container */
  }
  option,
  select,
  button{
    font-family: inherit; /* Use the same font as the rest of the app */
    background-color: var(--arcade-pink); /* Button background color */
    color: var(--arcade-white); /* Button text color */
    border: none; /* Remove default border */
    padding: 0.5rem 1rem;
    margin:  0.5rem .1rem; /* Padding for the button */
    font-size: 1rem; /* Font size for the button */
    cursor: pointer; /* Change cursor to pointer on hover */
    border-radius: 4px; /* Optional: rounded corners for buttons */
    transition: background-color 0.3s ease; /* Transition effect for hover */
  }
  button:hover {
    background-color: var(--arcade-blue); /* Change background color on hover */
    box-shadow: 0 0 12px var(--arcade-blue); /* Optional: add a glow effect on hover */
    color: var(--arcade-white); /* Ensure text color remains white */
  }
  .MuiButton-root {
    background-color: var(--arcade-white)!important; /* Override MUI default background color */
    color: var(--arcade-pink)!important; /* Override MUI default text color */
    border: 2px solid var(--arcade-green)!important; /* Override MUI default border color */
    font-family: 'Press Start 2P', monospace!important; /* Ensure the font is consistent with the rest of the app */
    font-size: 0.6rem;
    padding: 0.5rem 1rem;
    margin: .5rem .1rem !important; /* Add some margin for spacing between buttons */
    cursor: pointer;
    text-transform: none;
  }
  
  .MuiButton-root:hover {
    background-color: var(--arcade-blue)!important; /* Change background color on hover for MUI button */
    box-shadow: 0 0 12px var(--arcade-blue);
  }
h1 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    text-align: center;
}
h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--arcade-green); /* Use arcade green for h2 */
}
p {
    font-size: 1rem;
    margin-bottom: 1rem;
    line-height: 1.5; /* Improved readability */
    text-align: center; /* Center align paragraphs for consistency */
    color: var(--arcade-white); /* Use arcade white for paragraph text */
}

/* Form Styles */
.form-group {
    margin-bottom: 1rem;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
}
input {
    background-color: var(--arcade-bg); /* Use the arcade background color */
    color: var(--arcade-pink); /* Text color */
    border: 2px solid var(--arcade-blue); /* Border color */
    padding: 0.5rem; /* Padding for input */
    font-size: 1rem; /* Font size */
    width: 100%; /* Full width */
    box-sizing: border-box; /* Box sizing to include padding in width */
    border-radius: 4px; /* Optional: rounded corners */

}


