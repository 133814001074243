.landing-page {
  min-height: 100vh;
  background: linear-gradient(135deg, #0A0F0D, #1A1F1D);
  background-repeat: no-repeat;
  background-size: cover;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  font-family: 'Press Start 2P', monospace;
}

.landing-content {
  max-width: 48rem;
  text-align: center;
}

.landing-title {
  color: #00E5FF;
  text-shadow: 0 0 10px #00E5FF;
  margin-bottom: 1.5rem;
  animation: typing 2s steps(30, end) forwards;
  overflow: hidden;
  white-space: nowrap;
  border-right: 0;
}

.landing-subtitle {
  margin-bottom: 2rem;
}

.landing-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
}

@media (min-width: 640px) {
  .landing-buttons {
    flex-direction: row;
    justify-content: center;
  }
}

.primary-button {
  background-color: #FF2D95;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.75rem;
  box-shadow: 0 0 10px #FF2D95;
  transition: transform 0.2s ease;
}

.primary-button:hover {
  transform: scale(1.05);
}

.secondary-button {
  border: 2px solid #00E5FF;
  color: #00E5FF;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.75rem;
  transition: background-color 0.3s ease;
}

.secondary-button:hover {
  background-color: rgba(0, 229, 255, 0.1);
}

.feature-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 640px) {
  .feature-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.feature-card {
  background-color: #111;
  border-radius: 10px;
  box-shadow: 0 0 12px rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border: 1px solid #333;
  text-align: left;
  transition: transform 0.2s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-icon {
  margin-bottom: 1rem;
}

.feature-title {
  color: #00E5FF;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}

.feature-description {
  color: #FFFFFF;
  font-size: 0.625rem;
  line-height: 1.4;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-blue {
  color: #00E5FF;
}

.icon-coral {
  color: #FF2D95;
}

.icon-mint {
  color: #39FF14;
}